// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ixTg79UXFaUI5cqthodE{border-radius:5px;background:var(--color-border-lighter);padding:20px 25px}", "",{"version":3,"sources":["webpack://./src/client/components/wrappers/boxes/BoxDetails/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,sCAAA,CACA,iBAAA","sourcesContent":[".boxDetails\n  border-radius: 5px\n  background: var(--color-border-lighter)\n  padding: 20px 25px\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boxDetails": "ixTg79UXFaUI5cqthodE"
};
export default ___CSS_LOADER_EXPORT___;
