// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "textarea{resize:none !important;min-height:100px !important}.hzVSA9pEgXls__PEEFao{border-color:#d5d5d5 !important}.fzWEXdeLrmSJetc2k_wp{color:var(--color-main) !important}", "",{"version":3,"sources":["webpack://./src/client/components/molecules/form/DisabledTextArea/styles.module.sass"],"names":[],"mappings":"AAAA,SACE,sBAAA,CACA,2BAAA,CACF,sBACE,+BAAA,CACF,sBACE,kCAAA","sourcesContent":["textarea \n  resize: none !important\n  min-height: 100px !important\n.disabledBorderColor\n  border-color: #d5d5d5 !important\n.labelColor\n  color: var(--color-main) !important  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"disabledBorderColor": "hzVSA9pEgXls__PEEFao",
	"labelColor": "fzWEXdeLrmSJetc2k_wp"
};
export default ___CSS_LOADER_EXPORT___;
