// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lH6abGStJXk5LuZMDXie{display:inline-block;width:auto;height:30px;margin-left:8px;border-radius:5px;padding:5px 10px;background-color:#229a16;color:#fff;font-size:13px !important;font-weight:400;text-transform:uppercase}", "",{"version":3,"sources":["webpack://./src/client/components/organisms/tokens/TokensList/components/HeaderEdition/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,oBAAA,CACA,UAAA,CACA,WAAA,CACA,eAAA,CACA,iBAAA,CACA,gBAAA,CACA,wBAAA,CACA,UAAA,CACA,yBAAA,CACA,eAAA,CACA,wBAAA","sourcesContent":[".listed\n  display: inline-block\n  width: auto\n  height: 30px\n  margin-left: 8px\n  border-radius: 5px\n  padding: 5px 10px\n  background-color: #229a16\n  color: #fff\n  font-size: 13px !important\n  font-weight: 400\n  text-transform: uppercase "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listed": "lH6abGStJXk5LuZMDXie"
};
export default ___CSS_LOADER_EXPORT___;
