// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ILPP4E0dETOp25hIWBVk{position:fixed;right:20px;z-index:1000;top:70px}", "",{"version":3,"sources":["webpack://./src/client/components/templates/RegularTemplate/components/ProgressWidgets/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,UAAA,CACA,YAAA,CACA,QAAA","sourcesContent":[".progressWidgets\n  position: fixed\n  right: 20px\n  z-index: 1000\n  top: 70px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressWidgets": "ILPP4E0dETOp25hIWBVk"
};
export default ___CSS_LOADER_EXPORT___;
