// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jygr7llNl929wI7gZE4Y h2{width:100%}@media(max-width: 768px){.jygr7llNl929wI7gZE4Y h2{height:40px !important;overflow:auto}}.gEKwfx3ZS6YAsOLSQvfI{filter:invert(1);object-fit:contain !important}", "",{"version":3,"sources":["webpack://./src/client/pages/Assets/pages/Assets/components/modal/ModalPreview/styles.module.sass","webpack://./src/client/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,yBACE,UAAA,CCQA,yBDTF,yBAGI,sBAAA,CACA,aAAA,CAAA,CACJ,sBACE,gBAAA,CACA,6BAAA","sourcesContent":["@import \"@/client/assets/sass/base/breakpoints\"\n\n.redemptionContainer h2 \n  width: 100%  \n  @include mobile        \n    height: 40px !important    \n    overflow: auto\n.relightImg\n  filter: invert(1)    \n  object-fit: contain !important","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n   @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin sm   \n  @media (min-width: #{$screen-sm})\n    @content\n\n@mixin md\n   @media (min-width: #{$screen-md})\n    @content\n\n@mixin lg\n  @media (min-width: #{$screen-lg})\n    @content\n\n@mixin xl\n  @media (min-width: #{$screen-xl})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"redemptionContainer": "jygr7llNl929wI7gZE4Y",
	"relightImg": "gEKwfx3ZS6YAsOLSQvfI"
};
export default ___CSS_LOADER_EXPORT___;
