// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KPT0946Uxgt_fhiwVVVq{padding:0 48px;margin-left:16px}@media(max-width: 768px){.KPT0946Uxgt_fhiwVVVq{padding:0 30px;margin-left:0}}@media(max-width: 440px){.KPT0946Uxgt_fhiwVVVq{padding:0 24px}}.E5RG9jrCbMEqbXRw7Rid{padding:0 16px;margin-right:16px;float:right !important}@media(max-width: 768px){.E5RG9jrCbMEqbXRw7Rid{margin-right:0}}@media(max-width: 440px){.E5RG9jrCbMEqbXRw7Rid{margin-top:12px;float:left !important}}", "",{"version":3,"sources":["webpack://./src/client/components/organisms/tokens/ModalCertifiy/styles.module.sass","webpack://./src/client/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,cAAA,CACA,gBAAA,CCOA,yBDTF,sBAII,cAAA,CACA,aAAA,CAAA,CAAA,yBALJ,sBAOI,cAAA,CAAA,CACJ,sBACE,cAAA,CACA,iBAAA,CACA,sBAAA,CCFA,yBDDF,sBAKI,cAAA,CAAA,CCRD,yBDGH,sBAOI,eAAA,CACA,qBAAA,CAAA","sourcesContent":["@import \"@/client/assets/sass/base/breakpoints\"\n\n.cancelBtn\n  padding: 0 48px\n  margin-left: 16px\n  @include mobile\n    padding: 0 30px\n    margin-left: 0\n  @include small-mobile\n    padding: 0 24px\n.addBtn\n  padding: 0 16px\n  margin-right: 16px\n  float: right !important\n  @include mobile\n    margin-right: 0\n  @include small-mobile\n    margin-top: 12px\n    float: left !important","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n   @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin sm   \n  @media (min-width: #{$screen-sm})\n    @content\n\n@mixin md\n   @media (min-width: #{$screen-md})\n    @content\n\n@mixin lg\n  @media (min-width: #{$screen-lg})\n    @content\n\n@mixin xl\n  @media (min-width: #{$screen-xl})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cancelBtn": "KPT0946Uxgt_fhiwVVVq",
	"addBtn": "E5RG9jrCbMEqbXRw7Rid"
};
export default ___CSS_LOADER_EXPORT___;
