// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fASrtH3xl3P3ETW6oU0Y{height:340px;margin-top:10px}.Yg2EQGPmxp79B0zBSNJO{color:var(--color-main) !important}.ThtSZTfheGxpNcMoTiI4{width:100% !important;border-color:#dbdbdb;font-size:16px !important;font-family:\"Poppins\",sans-serif;color:#2b2b2b;font-weight:400}", "",{"version":3,"sources":["webpack://./src/client/components/organisms/tokens/ShareLink/components/InlineSMS/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,eAAA,CACF,sBACE,kCAAA,CACF,sBACE,qBAAA,CACA,oBAAA,CACA,yBAAA,CACA,gCAAA,CACA,aAAA,CACA,eAAA","sourcesContent":[".smsContainer  \n  height: 340px\n  margin-top: 10px\n.labelColor\n  color: var(--color-main) !important  \n.inputBig\n  width: 100% !important\n  border-color: #dbdbdb\n  font-size: 16px !important\n  font-family: 'Poppins', sans-serif\n  color: #2b2b2b\n  font-weight: 400    "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"smsContainer": "fASrtH3xl3P3ETW6oU0Y",
	"labelColor": "Yg2EQGPmxp79B0zBSNJO",
	"inputBig": "ThtSZTfheGxpNcMoTiI4"
};
export default ___CSS_LOADER_EXPORT___;
