// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jf0ehjUnX0E6JpicLqn4{width:100%;margin-top:20px}.bw8ctKqB4Bpkqq7m2a02{width:5%}.Q2BPgRKTxJ3tUrEdSS_d{width:95%}.G1ew_JwMCw4es_PQ5S4E{font-weight:600;color:var(--color-main)}", "",{"version":3,"sources":["webpack://./src/client/components/organisms/tokens/Recipients/styles.module.sass"],"names":[],"mappings":"AAEA,sBACE,UAAA,CACA,eAAA,CACF,sBACE,QAAA,CACF,sBACE,SAAA,CACF,sBACE,eAAA,CACA,uBAAA","sourcesContent":["@import \"@/client/assets/sass/base/breakpoints\"\n\n.recipientsContainer  \n  width: 100%\n  margin-top: 20px\n.iconColumn  \n  width: 5%  \n.userColumn  \n  width: 95%    \n.recipientTxt\n  font-weight: 600\n  color: var(--color-main) "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"recipientsContainer": "jf0ehjUnX0E6JpicLqn4",
	"iconColumn": "bw8ctKqB4Bpkqq7m2a02",
	"userColumn": "Q2BPgRKTxJ3tUrEdSS_d",
	"recipientTxt": "G1ew_JwMCw4es_PQ5S4E"
};
export default ___CSS_LOADER_EXPORT___;
