// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".o8a2X_B6q9zdySVFM0Gz{width:100%;height:234px}@media(max-width: 768px){.o8a2X_B6q9zdySVFM0Gz{height:auto}}", "",{"version":3,"sources":["webpack://./src/client/components/organisms/campaigns/CampaignCard/styles.module.sass","webpack://./src/client/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,UAAA,CACA,YAAA,CCOA,yBDTF,sBAII,WAAA,CAAA","sourcesContent":["@import \"@/client/assets/sass/base/breakpoints\"\n\n.campaignCard\n  width: 100% \n  height: 234px\n  @include mobile\n    height: auto  ","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n   @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin sm   \n  @media (min-width: #{$screen-sm})\n    @content\n\n@mixin md\n   @media (min-width: #{$screen-md})\n    @content\n\n@mixin lg\n  @media (min-width: #{$screen-lg})\n    @content\n\n@mixin xl\n  @media (min-width: #{$screen-xl})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"campaignCard": "o8a2X_B6q9zdySVFM0Gz"
};
export default ___CSS_LOADER_EXPORT___;
