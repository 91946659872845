// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".O9YMJTUTD627CUlrKi8B{color:var(--color-main);font-weight:600 !important}", "",{"version":3,"sources":["webpack://./src/client/components/molecules/form/CheckBoxDefault/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,uBAAA,CACA,0BAAA","sourcesContent":[".checkBoxLabel\n  color: var(--color-main)\n  font-weight: 600 !important"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkBoxLabel": "O9YMJTUTD627CUlrKi8B"
};
export default ___CSS_LOADER_EXPORT___;
