// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XSil8pq4gsjGBYPWem8A{width:135px;height:40px;border-radius:4px !important;border:1px solid #ccc !important;background:#fff;color:#474747;font-size:16px;font-weight:500 !important}.XSil8pq4gsjGBYPWem8A:hover{margin-top:-4px;opacity:.8}.XSil8pq4gsjGBYPWem8A svg{margin-top:5px}.Onl1ym9Oya0nGMNl7zYX{width:30px !important;height:29px !important;padding:5px 7px !important;border-radius:4px !important;border:1px solid #ccc;background:#fff;cursor:pointer}.Onl1ym9Oya0nGMNl7zYX:hover{margin-top:-4px;opacity:.8}", "",{"version":3,"sources":["webpack://./src/client/components/organisms/tokens/ShareLink/components/TwilioButton/styles.module.sass"],"names":[],"mappings":"AAEA,sBACE,WAAA,CACA,WAAA,CACA,4BAAA,CACA,gCAAA,CACA,eAAA,CACA,aAAA,CACA,cAAA,CACA,0BAAA,CACA,4BACE,eAAA,CACA,UAAA,CACF,0BACE,cAAA,CACJ,sBACE,qBAAA,CACA,sBAAA,CACA,0BAAA,CACA,4BAAA,CACA,qBAAA,CACA,eAAA,CACA,cAAA,CACA,4BACE,eAAA,CACA,UAAA","sourcesContent":["@import \"@/client/assets/sass/base/breakpoints\"\n\n.twilioBtn\n  width: 135px\n  height: 40px  \n  border-radius: 4px !important\n  border: 1px solid #cccccc !important\n  background: #ffffff  \n  color: #474747\n  font-size: 16px\n  font-weight: 500 !important\n  &:hover\n    margin-top: -4px\n    opacity: 0.8   \n  svg\n    margin-top: 5px  \n.twilioSmallBtn\n  width: 30px !important\n  height: 29px !important\n  padding: 5px 7px !important\n  border-radius: 4px !important\n  border: 1px solid #cccccc\n  background: #ffffff  \n  cursor: pointer\n  &:hover\n    margin-top: -4px\n    opacity: 0.8"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"twilioBtn": "XSil8pq4gsjGBYPWem8A",
	"twilioSmallBtn": "Onl1ym9Oya0nGMNl7zYX"
};
export default ___CSS_LOADER_EXPORT___;
