// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LlaAg732jK9ekd5OaYia{position:relative}.LlaAg732jK9ekd5OaYia:hover .QlfKjfj4b15dORynJ4H2{display:block}.s3C4I3QRlRkZIUW_21C6{background:rgba(0,0,0,0);text-decoration:underline;border:none;font-size:14px;display:block}.s3C4I3QRlRkZIUW_21C6:hover{color:var(--color-secondary);cursor:pointer}.QlfKjfj4b15dORynJ4H2{display:none;position:absolute;background:#fff;top:-25px;width:300px;z-index:10;font-size:11px;padding:5px;border-radius:3px;box-shadow:3px 2px 4px -1px #d8d8d8}", "",{"version":3,"sources":["webpack://./src/client/pages/CampaignsRoutes/pages/CampaignRoutes/pages/Campaign/components/Recipients/components/CellButton/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,kDACE,aAAA,CACJ,sBACE,wBAAA,CACA,yBAAA,CACA,WAAA,CACA,cAAA,CACA,aAAA,CACA,4BACE,4BAAA,CACA,cAAA,CACJ,sBACE,YAAA,CACA,iBAAA,CACA,eAAA,CACA,SAAA,CACA,WAAA,CACA,UAAA,CACA,cAAA,CACA,WAAA,CACA,iBAAA,CACA,mCAAA","sourcesContent":[".buttonContainer\n  position: relative\n  &:hover .tooltip\n    display: block\n.button\n  background: transparent\n  text-decoration: underline\n  border: none\n  font-size: 14px\n  display: block\n  &:hover\n    color: var(--color-secondary)\n    cursor: pointer\n.tooltip\n  display: none\n  position: absolute\n  background: #fff\n  top: -25px\n  width: 300px\n  z-index: 10\n  font-size: 11px\n  padding: 5px\n  border-radius: 3px\n  box-shadow: 3px 2px 4px -1px #d8d8d8\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonContainer": "LlaAg732jK9ekd5OaYia",
	"tooltip": "QlfKjfj4b15dORynJ4H2",
	"button": "s3C4I3QRlRkZIUW_21C6"
};
export default ___CSS_LOADER_EXPORT___;
