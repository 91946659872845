// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HgxKkN8uX8XdOUlIQpGM{display:grid;grid-auto-rows:auto;grid-auto-columns:max-content;grid-template-columns:repeat(auto-fill, minmax(50px, 10px));grid-column-gap:1rem;grid-row-gap:1rem;text-align:center}.DIDfUu9jUwxj0_kZQTq_{cursor:pointer;background:rgba(0,0,0,0);border:1px solid var(--color-default-text);padding:10px;border-radius:10px;width:50px;line-height:0}.DIDfUu9jUwxj0_kZQTq_:hover,.DIDfUu9jUwxj0_kZQTq_.FVJ5qkjlgwYwdGG1Ss9T{border-color:var(--color-secondary)}", "",{"version":3,"sources":["webpack://./src/client/pages/User/pages/CampaignTemplates/pages/CreateTemplate/components/CategoryManager/components/Category/components/FormHeaderDetails/components/SelectIcon/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,mBAAA,CACA,6BAAA,CACA,2DAAA,CACA,oBAAA,CACA,iBAAA,CACA,iBAAA,CACF,sBACE,cAAA,CACA,wBAAA,CACA,0CAAA,CACA,YAAA,CACA,kBAAA,CACA,UAAA,CACA,aAAA,CACA,uEAEE,mCAAA","sourcesContent":[".icons\n  display: grid\n  grid-auto-rows: auto\n  grid-auto-columns: max-content\n  grid-template-columns: repeat(auto-fill,minmax(50px,10px))\n  grid-column-gap: 1rem\n  grid-row-gap: 1rem\n  text-align: center\n.icon\n  cursor: pointer\n  background: transparent\n  border: 1px solid var(--color-default-text)\n  padding: 10px\n  border-radius: 10px\n  width: 50px\n  line-height: 0\n  &:hover,\n  &.selected\n    border-color: var(--color-secondary)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icons": "HgxKkN8uX8XdOUlIQpGM",
	"icon": "DIDfUu9jUwxj0_kZQTq_",
	"selected": "FVJ5qkjlgwYwdGG1Ss9T"
};
export default ___CSS_LOADER_EXPORT___;
