// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HBkt0gmBV9_tbNXS6WxX ul{width:50%;table-layout:auto !important}", "",{"version":3,"sources":["webpack://./src/client/components/organisms/campaigns/CampaignAssetCard/components/Body/styles.module.sass"],"names":[],"mappings":"AAAA,yBACE,SAAA,CACA,4BAAA","sourcesContent":[".bodyCampaignCard ul\n  width: 50%  \n  table-layout: auto !important"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bodyCampaignCard": "HBkt0gmBV9_tbNXS6WxX"
};
export default ___CSS_LOADER_EXPORT___;
