// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AlPKSASdNhQBmRtNGjut{font-weight:bold;font-size:14px;margin-bottom:5px}", "",{"version":3,"sources":["webpack://./src/client/pages/User/pages/CampaignTemplates/pages/ListTemplates/components/SelectCategorySection/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,cAAA,CACA,iBAAA","sourcesContent":[".label\n  font-weight: bold\n  font-size: 14px\n  margin-bottom: 5px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "AlPKSASdNhQBmRtNGjut"
};
export default ___CSS_LOADER_EXPORT___;
