// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lG7MZx36BPq8LuxUj9w6{width:20%;margin-top:7px !important}.ICZT9gbCsvhfGJKHXb4T{width:49%;margin-top:7px !important}@media(max-width: 440px){.ICZT9gbCsvhfGJKHXb4T{width:54%}}.Hd4KHGqdDdYM4rTp3nQ3{background:none;border-left:1px solid var(--color-border-lighter) !important}.Hd4KHGqdDdYM4rTp3nQ3:hover{background:var(--color-main)}.Hd4KHGqdDdYM4rTp3nQ3:hover img{filter:brightness(0) invert(1)}.dKoBbpFcv09lDjD0tf0L{background:#fafafa;display:flex;justify-content:space-between}.MF40lOv_B1j7j44V4XGA{border-radius:0 !important}", "",{"version":3,"sources":["webpack://./src/client/components/organisms/campaigns/CampaignAssetCard/components/Footer/styles.module.sass"],"names":[],"mappings":"AAEA,sBACE,SAAA,CACA,yBAAA,CACF,sBACE,SAAA,CACA,yBAAA,CAAA,yBAFF,sBAII,SAAA,CAAA,CACJ,sBACE,eAAA,CACA,4DAAA,CACA,4BACE,4BAAA,CACA,gCACE,8BAAA,CACN,sBACE,kBAAA,CACA,YAAA,CACA,6BAAA,CACF,sBACE,0BAAA","sourcesContent":["@import \"@/client/assets/sass/base/breakpoints\"\n\n.iconBtnContainer\n  width: 20%\n  margin-top: 7px !important\n.btnContainer\n  width: 49%  \n  margin-top: 7px !important  \n  @include small-mobile\n    width: 54%\n.iconButton\n  background: none\n  border-left: 1px solid var(--color-border-lighter) !important\n  &:hover\n    background: var(--color-main) \n    img\n      filter: brightness(0) invert(1)\n.footer\n  background: #FAFAFA\n  display: flex\n  justify-content: space-between  \n.btnBorderRadius\n  border-radius: 0 !important"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconBtnContainer": "lG7MZx36BPq8LuxUj9w6",
	"btnContainer": "ICZT9gbCsvhfGJKHXb4T",
	"iconButton": "Hd4KHGqdDdYM4rTp3nQ3",
	"footer": "dKoBbpFcv09lDjD0tf0L",
	"btnBorderRadius": "MF40lOv_B1j7j44V4XGA"
};
export default ___CSS_LOADER_EXPORT___;
