// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wpL5QpPk1Ghkmff7RK7S{font-size:14px;font-weight:600;color:#1c2646}.aQ91OZXCXjByWw6pd4QD{width:38px;height:38px;border-top-left-radius:2px;border-bottom-left-radius:2px}.PkSkWRlOYJp_5Hg1fXC0{width:113px;height:38px;background-color:#fff;border-top-right-radius:2px;border-bottom-right-radius:2px;font-size:16px;border:1px solid rgba(172,172,172,.1411764706);border-left:none;color:#1c2646}", "",{"version":3,"sources":["webpack://./src/client/pages/User/pages/CampaignTemplates/pages/ListTemplates/components/ColorsPalette/components/Palette/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,eAAA,CACA,aAAA,CACF,sBACE,UAAA,CACA,WAAA,CACA,0BAAA,CACA,6BAAA,CACF,sBACE,WAAA,CACA,WAAA,CACA,qBAAA,CACA,2BAAA,CACA,8BAAA,CACA,cAAA,CACA,8CAAA,CACA,gBAAA,CACA,aAAA","sourcesContent":[".subtitle\n  font-size: 14px\n  font-weight: 600\n  color: #1C2646\n.colorContainer\n  width: 38px\n  height: 38px\n  border-top-left-radius: 2px\n  border-bottom-left-radius: 2px\n.valueContainer\n  width: 113px\n  height: 38px\n  background-color: #fff\n  border-top-right-radius: 2px\n  border-bottom-right-radius: 2px\n  font-size: 16px\n  border: 1px solid #ACACAC24\n  border-left: none\n  color: #1C2646\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subtitle": "wpL5QpPk1Ghkmff7RK7S",
	"colorContainer": "aQ91OZXCXjByWw6pd4QD",
	"valueContainer": "PkSkWRlOYJp_5Hg1fXC0"
};
export default ___CSS_LOADER_EXPORT___;
