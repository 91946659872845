// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QhWlMVEh5wufzKftPKZz{color:var(--color-main)}", "",{"version":3,"sources":["webpack://./src/client/components/organisms/common/InputText/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,uBAAA","sourcesContent":[".labelColor\n  color: var(--color-main)"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labelColor": "QhWlMVEh5wufzKftPKZz"
};
export default ___CSS_LOADER_EXPORT___;
