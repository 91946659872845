// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".g1aPllUh2LyCfyyUM5nD{filter:invert(1);object-fit:contain !important}", "",{"version":3,"sources":["webpack://./src/client/components/wrappers/modals/ModalPreviewImage/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,6BAAA","sourcesContent":[".relightImg\n  filter: invert(1)    \n  object-fit: contain !important"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"relightImg": "g1aPllUh2LyCfyyUM5nD"
};
export default ___CSS_LOADER_EXPORT___;
