// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Hczjjf22b9K_E_YOEBJz{margin-bottom:.75rem}.moqFbCYRjQ7HkOCoUdBA{padding:0 48px;margin-left:16px}@media(max-width: 768px){.moqFbCYRjQ7HkOCoUdBA{padding:0 16px}}@media(max-width: 440px){.moqFbCYRjQ7HkOCoUdBA{padding:0 8px;margin-left:0}}.a6nSG7MNX9_CYX4teQuY{float:right !important;margin-right:16px}@media(max-width: 440px){.a6nSG7MNX9_CYX4teQuY{float:left !important;margin-top:8px;margin-right:0}}", "",{"version":3,"sources":["webpack://./src/client/components/organisms/tokens/ModalAddCertifications/styles.module.sass","webpack://./src/client/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,oBAAA,CACF,sBACE,cAAA,CACA,gBAAA,CCKA,yBDPF,sBAII,cAAA,CAAA,CCDD,yBDHH,sBAMI,aAAA,CACA,aAAA,CAAA,CACJ,sBACE,sBAAA,CACA,iBAAA,CCPC,yBDKH,sBAII,qBAAA,CACA,cAAA,CACA,cAAA,CAAA","sourcesContent":["@import \"@/client/assets/sass/base/breakpoints\"\n\n.inputTooltip\n  margin-bottom: 0.75rem\n.cancelBtn\n  padding: 0 48px\n  margin-left: 16px\n  @include mobile\n    padding: 0 16px    \n  @include small-mobile\n    padding: 0 8px\n    margin-left: 0 \n.certificationBtn\n  float: right !important\n  margin-right: 16px\n  @include small-mobile\n    float: left !important\n    margin-top: 8px\n    margin-right: 0  ","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n   @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin sm   \n  @media (min-width: #{$screen-sm})\n    @content\n\n@mixin md\n   @media (min-width: #{$screen-md})\n    @content\n\n@mixin lg\n  @media (min-width: #{$screen-lg})\n    @content\n\n@mixin xl\n  @media (min-width: #{$screen-xl})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputTooltip": "Hczjjf22b9K_E_YOEBJz",
	"cancelBtn": "moqFbCYRjQ7HkOCoUdBA",
	"certificationBtn": "a6nSG7MNX9_CYX4teQuY"
};
export default ___CSS_LOADER_EXPORT___;
