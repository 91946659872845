// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zdNP6GA6UeVVeyhwx8nT{border-radius:5px;border:1px solid #ccc;padding:15px}.zdNP6GA6UeVVeyhwx8nT .uBauXkh4SeO6Zx_e5cfg{font-size:18px;margin-bottom:10px}.VMRMF0bzKX5bd6dLgXn8{font-size:18px;text-align:center}.rMw29u0AmdT1Oc55XDuB>*{padding:15px}.rMw29u0AmdT1Oc55XDuB>*:nth-child(even){background:#fff}", "",{"version":3,"sources":["webpack://./src/client/pages/User/pages/CampaignTemplates/pages/CreateTemplate/components/CategoryManager/components/Sections/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,qBAAA,CACA,YAAA,CACA,4CACE,cAAA,CACA,kBAAA,CAEJ,sBACE,cAAA,CACA,iBAAA,CACF,wBACE,YAAA,CACF,wCACE,eAAA","sourcesContent":[".sectionForm\n  border-radius: 5px\n  border: 1px solid #ccc\n  padding: 15px\n  .title\n    font-size: 18px\n    margin-bottom: 10px\n\n.noSectionLegend\n  font-size: 18px\n  text-align: center\n.listSections > *\n  padding: 15px\n.listSections > *:nth-child(even)\n  background: #fff\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionForm": "zdNP6GA6UeVVeyhwx8nT",
	"title": "uBauXkh4SeO6Zx_e5cfg",
	"noSectionLegend": "VMRMF0bzKX5bd6dLgXn8",
	"listSections": "rMw29u0AmdT1Oc55XDuB"
};
export default ___CSS_LOADER_EXPORT___;
