// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QHSWPajOZuMXwEL_EUi7{padding:0 48px;margin-left:16px}@media(max-width: 768px){.QHSWPajOZuMXwEL_EUi7{padding:0 20px}}@media(max-width: 440px){.QHSWPajOZuMXwEL_EUi7{padding:0 12px !important;margin-left:0}}.TIA_VRVDuY9sthCU3fx2{padding:0 48px !important;margin-right:16px}@media(max-width: 768px){.TIA_VRVDuY9sthCU3fx2{padding:0 30px !important}}@media(max-width: 440px){.TIA_VRVDuY9sthCU3fx2{padding:0 24px !important;margin-right:0}}", "",{"version":3,"sources":["webpack://./src/client/components/organisms/tokens/ModalMintRewardTokens/styles.module.sass","webpack://./src/client/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,cAAA,CACA,gBAAA,CCOA,yBDTF,sBAII,cAAA,CAAA,CCCD,yBDLH,sBAMI,yBAAA,CACA,aAAA,CAAA,CACJ,sBACE,yBAAA,CACA,iBAAA,CCDA,yBDDF,sBAII,yBAAA,CAAA,CCPD,yBDGH,sBAMI,yBAAA,CACA,cAAA,CAAA","sourcesContent":["@import \"@/client/assets/sass/base/breakpoints\"\n\n.cancelBtn\n  padding: 0 48px\n  margin-left: 16px\n  @include mobile\n    padding: 0 20px\n  @include small-mobile\n    padding: 0 12px !important\n    margin-left: 0\n.mintBtn    \n  padding: 0 48px !important\n  margin-right: 16px\n  @include mobile\n    padding: 0 30px !important\n  @include small-mobile\n    padding: 0 24px !important\n    margin-right: 0","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n   @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin sm   \n  @media (min-width: #{$screen-sm})\n    @content\n\n@mixin md\n   @media (min-width: #{$screen-md})\n    @content\n\n@mixin lg\n  @media (min-width: #{$screen-lg})\n    @content\n\n@mixin xl\n  @media (min-width: #{$screen-xl})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cancelBtn": "QHSWPajOZuMXwEL_EUi7",
	"mintBtn": "TIA_VRVDuY9sthCU3fx2"
};
export default ___CSS_LOADER_EXPORT___;
