// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._fq84S4E7scNlSyBX4lV .ueVHLgCk5aUyY5QZW4Ri.y7OMcNmSZ7q7En8urkBa{display:flex;align-items:center;justify-content:space-between;width:210px}._fq84S4E7scNlSyBX4lV .zKAsTCrJnsrue4zKjI2H{margin-right:0}._fq84S4E7scNlSyBX4lV .CDbl_L4W9JZYcMGmZ6Ec{position:absolute;background:#fff;z-index:100}._fq84S4E7scNlSyBX4lV .ueVHLgCk5aUyY5QZW4Ri{width:180px}.ueVHLgCk5aUyY5QZW4Ri{border:none;background:#fff;padding:.75rem 1.25rem .75rem 1.25rem;color:var(--color-main);font-weight:600;cursor:pointer}.ueVHLgCk5aUyY5QZW4Ri.FUKxkMvcpnWZnFTdCBfm{background-color:var(--color-main) !important;color:#fff !important}.ueVHLgCk5aUyY5QZW4Ri:hover{background-color:var(--color-main);color:#fff !important}", "",{"version":3,"sources":["webpack://./src/client/pages/CampaignsRoutes/pages/CampaignRoutes/pages/Campaign/components/SelectTabs/styles.module.sass"],"names":[],"mappings":"AAIE,iEACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,WAAA,CACF,4CACE,cAAA,CACF,4CACE,iBAAA,CACA,eAAA,CACA,WAAA,CACF,4CACE,WAAA,CACJ,sBACE,WAAA,CACA,eAAA,CACA,qCAAA,CACA,uBAAA,CACA,eAAA,CACA,cAAA,CACA,2CACE,6CAAA,CACA,qBAAA,CACF,4BACE,kCAAA,CACA,qBAAA","sourcesContent":["@import \"@/client/assets/sass/base/spacing\" \n\n\n.tabsSelect\n  .tabItem.selTab\n    display: flex\n    align-items: center\n    justify-content: space-between\n    width: 210px\n  .tabItemContainer\n    margin-right: 0\n  .tabsSelectList\n    position: absolute\n    background: #fff\n    z-index: 100\n  .tabItem\n    width: 180px\n.tabItem\n  border: none\n  background: #fff\n  padding: $spacing-sm $spacing-md $spacing-sm $spacing-md\n  color: var(--color-main)\n  font-weight: 600\n  cursor: pointer\n  &.active\n    background-color: var(--color-main) !important\n    color: #fff !important\n  &:hover\n    background-color: var(--color-main)\n    color: #fff !important\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabsSelect": "_fq84S4E7scNlSyBX4lV",
	"tabItem": "ueVHLgCk5aUyY5QZW4Ri",
	"selTab": "y7OMcNmSZ7q7En8urkBa",
	"tabItemContainer": "zKAsTCrJnsrue4zKjI2H",
	"tabsSelectList": "CDbl_L4W9JZYcMGmZ6Ec",
	"active": "FUKxkMvcpnWZnFTdCBfm"
};
export default ___CSS_LOADER_EXPORT___;
