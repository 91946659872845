// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qSTc2WZw_mQltLcqN2Lh{background:#fafafa;display:flex;justify-content:space-between}@media(max-width: 768px){.qSTc2WZw_mQltLcqN2Lh{display:flex}}@media(max-width: 440px){.qSTc2WZw_mQltLcqN2Lh{display:block}}", "",{"version":3,"sources":["webpack://./src/client/components/organisms/campaigns/CampaignCard/components/Footer/styles.module.sass","webpack://./src/client/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAGA,sBACE,kBAAA,CACA,YAAA,CACA,6BAAA,CCKA,yBDRF,sBAKI,YAAA,CAAA,CCDD,yBDJH,sBAOI,aAAA,CAAA","sourcesContent":["@import \"@/client/assets/sass/base/spacing\"\n@import \"@/client/assets/sass/base/breakpoints\"\n\n.footer\n  background: #FAFAFA\n  display: flex\n  justify-content: space-between\n  @include mobile\n    display: flex\n  @include small-mobile\n    display: block    \n","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n   @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin sm   \n  @media (min-width: #{$screen-sm})\n    @content\n\n@mixin md\n   @media (min-width: #{$screen-md})\n    @content\n\n@mixin lg\n  @media (min-width: #{$screen-lg})\n    @content\n\n@mixin xl\n  @media (min-width: #{$screen-xl})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "qSTc2WZw_mQltLcqN2Lh"
};
export default ___CSS_LOADER_EXPORT___;
