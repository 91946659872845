// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VSxVCdd3HyPisFucDUCO{font-weight:600}.MSWRLgqZdg5U8p5ojjwa{padding:0 12px !important}.MSWRLgqZdg5U8p5ojjwa:hover img{filter:brightness(0) invert(1)}.EuiDkZVxqVen2vzbU1uQ{filter:brightness(0) invert(0.5)}", "",{"version":3,"sources":["webpack://./src/client/components/organisms/tokens/ShareLink/components/QRCodeButton/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACF,sBACE,yBAAA,CAEE,gCACE,8BAAA,CACN,sBACE,gCAAA","sourcesContent":[".toolTipLabel  \n  font-weight: 600  \n.iconButton\n  padding: 0 12px !important\n  &:hover   \n    img\n      filter: brightness(0) invert(1)\n.disabledIcon\n  filter: brightness(0) invert(0.5)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolTipLabel": "VSxVCdd3HyPisFucDUCO",
	"iconButton": "MSWRLgqZdg5U8p5ojjwa",
	"disabledIcon": "EuiDkZVxqVen2vzbU1uQ"
};
export default ___CSS_LOADER_EXPORT___;
