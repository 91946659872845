// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DfLYLkJmlEXoDxq81dBy,.dVmQNuLMmdrgQMUgeieS{line-height:1.8}.DfLYLkJmlEXoDxq81dBy{font-weight:600}", "",{"version":3,"sources":["webpack://./src/client/components/molecules/asset/lists/ListDetails/styles.module.sass"],"names":[],"mappings":"AAAA,4CAEE,eAAA,CACF,sBACE,eAAA","sourcesContent":[".labels,\n.texts\n  line-height: 1.8\n.labels\n  font-weight: 600\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labels": "DfLYLkJmlEXoDxq81dBy",
	"texts": "dVmQNuLMmdrgQMUgeieS"
};
export default ___CSS_LOADER_EXPORT___;
