// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JYn4H5SRiKPxfl0TJ_ZA{font-size:13px}", "",{"version":3,"sources":["webpack://./src/client/pages/CampaignsRoutes/pages/CampaignRoutes/pages/Campaign/components/Recipients/components/MessageLogButtons/styles.modules.sass"],"names":[],"mappings":"AAAA,sBACE,cAAA","sourcesContent":[".button\n  font-size: 13px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "JYn4H5SRiKPxfl0TJ_ZA"
};
export default ___CSS_LOADER_EXPORT___;
