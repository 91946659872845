// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".V2AoeA_a_FionfH31XWX{background:#fff;box-shadow:0px 3px 6px rgba(0,0,0,.1607843137);border-radius:4px}", "",{"version":3,"sources":["webpack://./src/client/components/wrappers/panels/PanelMenu/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,8CAAA,CACA,iBAAA","sourcesContent":[".panelMenu\n  background: #fff\n  box-shadow: 0px 3px 6px #00000029\n  border-radius: 4px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panelMenu": "V2AoeA_a_FionfH31XWX"
};
export default ___CSS_LOADER_EXPORT___;
