// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vZRnnruL4w48ByRfKku1{position:relative}.STl0ZoLOORornM2Op3BF{height:120px;width:100%;overflow:hidden;background:rgba(0,0,0,.3);margin-bottom:.75rem;position:relative}.STl0ZoLOORornM2Op3BF img{height:100%;width:100%;object-fit:cover}.HRxlLAzyS3Fqj1wSsSU1{font-size:16px;font-weight:600;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;margin-left:20px}", "",{"version":3,"sources":["webpack://./src/client/components/organisms/assets/PNFTCard/components/Header/styles.module.sass","webpack://./src/client/assets/sass/base/_units.sass"],"names":[],"mappings":"AAEA,sBACE,iBAAA,CACF,sBACE,YAAA,CACA,UAAA,CACA,eAAA,CACA,yBAAA,CACA,oBCLG,CDMH,iBAAA,CACF,0BACE,WAAA,CACA,UAAA,CACA,gBAAA,CACF,sBACE,cAAA,CACA,eAAA,CACA,eAAA,CACA,kBAAA,CACA,sBAAA,CACA,gBAAA","sourcesContent":["@import \"@/client/assets/sass/base/spacing\"\n\n.headerPNFT\n  position: relative\n.imageActions\n  height: 120px\n  width: 100%\n  overflow: hidden\n  background: rgba(0, 0, 0, 0.3)\n  margin-bottom: $spacing-sm\n  position: relative\n.imageActions img  \n  height: 100%\n  width: 100%\n  object-fit: cover\n.title\n  font-size: 16px\n  font-weight: 600\n  overflow: hidden\n  white-space: nowrap\n  text-overflow: ellipsis\n  margin-left: 20px  ","$u1: 0.25rem      // 4px\n$u1-5: 0.375rem   // 6px\n$u2: 0.5rem       // 8px\n$u2-5: 0.625rem   // 10px\n$u3: 0.75rem      // 12px\n$u3-5: 0.875rem   // 14px\n$u4: 1rem         // 16px\n$u5: 1.25rem      // 20px\n$u6: 1.5rem       // 24px\n$u7: 1.75rem      // 28px\n$u8: 2rem         // 32px\n$u9: 2.25rem      // 36px\n$u10: 2.5rem      // 40px\n$u11: 2.75rem     // 44px\n$u12: 3rem        // 48px\n$u13: 3.25rem     // 52px\n$u14: 3.5rem      // 56px\n$u15: 3.75rem     // 60px\n$u16: 4rem        // 64px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerPNFT": "vZRnnruL4w48ByRfKku1",
	"imageActions": "STl0ZoLOORornM2Op3BF",
	"title": "HRxlLAzyS3Fqj1wSsSU1"
};
export default ___CSS_LOADER_EXPORT___;
