// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".K6b8ATSuSIAg8EWZJCFy{margin:-1px 0 0 -2px}.cJe0G9lvtXqC9ZsmtHrd{color:var(--color-main);font-weight:600 !important}._OCUM44_8Eh4RWhwRsxh{width:22px !important;height:22px}._OCUM44_8Eh4RWhwRsxh>input[type=checkbox]{width:15px !important;height:15px !important}.bOqflqDdFwoeeohPDOhu{width:40px !important;height:22px}.bOqflqDdFwoeeohPDOhu svg{margin-left:8px}.bOqflqDdFwoeeohPDOhu img{margin-left:7px}.Tn9Wz_etdKvvdbfTQJrP{height:22px}", "",{"version":3,"sources":["webpack://./src/client/components/molecules/form/CheckBoxWithIcon/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,oBAAA,CACF,sBACE,uBAAA,CACA,0BAAA,CACF,sBACE,qBAAA,CACA,WAAA,CACA,2CACE,qBAAA,CACA,sBAAA,CACJ,sBACE,qBAAA,CACA,WAAA,CACA,0BACE,eAAA,CACF,0BACE,eAAA,CACJ,sBACE,WAAA","sourcesContent":[".imgMargin\n  margin: -1px 0 0 -2px\n.checkBoxLabel\n  color: var(--color-main)\n  font-weight: 600 !important\n.checkboxCol\n  width: 22px !important\n  height: 22px  \n  > input[type=\"checkbox\"]\n    width: 15px !important\n    height: 15px !important\n.iconCol\n  width: 40px !important\n  height: 22px\n  svg\n    margin-left: 8px    \n  img\n    margin-left: 7px    \n.labelCol\n  height: 22px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imgMargin": "K6b8ATSuSIAg8EWZJCFy",
	"checkBoxLabel": "cJe0G9lvtXqC9ZsmtHrd",
	"checkboxCol": "_OCUM44_8Eh4RWhwRsxh",
	"iconCol": "bOqflqDdFwoeeohPDOhu",
	"labelCol": "Tn9Wz_etdKvvdbfTQJrP"
};
export default ___CSS_LOADER_EXPORT___;
