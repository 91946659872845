// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".u8AcOo2kWwkcaujpd28w{margin:0 35px 35px 35px}@media(max-width: 440px){.u8AcOo2kWwkcaujpd28w{margin:0 20px 20px 20px}}.xztqDNAUdv0_A7NHL_vw{width:50% !important}@media(max-width: 768px){.xztqDNAUdv0_A7NHL_vw{width:100% !important}}", "",{"version":3,"sources":["webpack://./src/client/pages/Listings/pages/OpenSentOffers/styles.module.sass","webpack://./src/client/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,uBAAA,CCIC,yBDLH,sBAGI,uBAAA,CAAA,CACJ,sBACE,oBAAA,CCIA,yBDLF,sBAGI,qBAAA,CAAA","sourcesContent":["@import \"@/client/assets/sass/base/breakpoints\"\n\n.margin\n  margin: 0 35px 35px 35px\n  @include small-mobile\n    margin: 0 20px 20px 20px\n.dataContainer\n  width: 50% !important  \n  @include mobile\n    width: 100% !important","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n   @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin sm   \n  @media (min-width: #{$screen-sm})\n    @content\n\n@mixin md\n   @media (min-width: #{$screen-md})\n    @content\n\n@mixin lg\n  @media (min-width: #{$screen-lg})\n    @content\n\n@mixin xl\n  @media (min-width: #{$screen-xl})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"margin": "u8AcOo2kWwkcaujpd28w",
	"dataContainer": "xztqDNAUdv0_A7NHL_vw"
};
export default ___CSS_LOADER_EXPORT___;
