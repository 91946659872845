// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MUI63gDLAsRIVgaDZRMe{display:flex;margin-bottom:20px}@media(max-width: 768px){.MUI63gDLAsRIVgaDZRMe{display:block}}.DrE7DS43YZH40hRw4TDb{margin-right:20px;display:flex;align-items:center}.XDpvIreeEigpOHizhmOD{margin-right:10px}.XDpvIreeEigpOHizhmOD:last-child{margin-right:0}@media(max-width: 768px){.XDpvIreeEigpOHizhmOD{margin-top:8px}}", "",{"version":3,"sources":["webpack://./src/client/components/organisms/tokens/TokensList/components/HeaderTokensList/styles.module.sass","webpack://./src/client/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,kBAAA,CCOA,yBDTF,sBAII,aAAA,CAAA,CACJ,sBACE,iBAAA,CACA,YAAA,CACA,kBAAA,CACF,sBACE,iBAAA,CACA,iCACE,cAAA,CCHF,yBAAA,sBDKE,cAAA,CAAA","sourcesContent":["@import \"@/client/assets/sass/base/breakpoints\"\n\n.headers\n  display: flex\n  margin-bottom: 20px\n  @include mobile\n    display: block\n.title\n  margin-right: 20px\n  display: flex\n  align-items: center\n.button\n  margin-right: 10px\n  &:last-child\n    margin-right: 0\n  @include mobile\n    margin-top: 8px  \n\n","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n   @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin sm   \n  @media (min-width: #{$screen-sm})\n    @content\n\n@mixin md\n   @media (min-width: #{$screen-md})\n    @content\n\n@mixin lg\n  @media (min-width: #{$screen-lg})\n    @content\n\n@mixin xl\n  @media (min-width: #{$screen-xl})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headers": "MUI63gDLAsRIVgaDZRMe",
	"title": "DrE7DS43YZH40hRw4TDb",
	"button": "XDpvIreeEigpOHizhmOD"
};
export default ___CSS_LOADER_EXPORT___;
