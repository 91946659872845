// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GtFvReenFEZ7SB83o8Ps>*{z-index:99 !important}", "",{"version":3,"sources":["webpack://./src/client/components/organisms/tokens/NicknameTypeahead/styles.module.sass"],"names":[],"mappings":"AAAA,wBACI,qBAAA","sourcesContent":[".searchNickname > *    \n    z-index: 99 !important"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchNickname": "GtFvReenFEZ7SB83o8Ps"
};
export default ___CSS_LOADER_EXPORT___;
