// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xajpiisda02qlmxX7h1A{color:var(--color-default-text);font-size:14px;margin-top:5px !important}.rs7bw2R27XXIpo_mgzkU{margin-top:35px !important}.LcLhWq2gO4OQgR0zJPIU{padding:0 30px !important}@media(max-width: 440px){.LcLhWq2gO4OQgR0zJPIU{padding:0 12px !important}}", "",{"version":3,"sources":["webpack://./src/client/pages/CampaignsRoutes/pages/CampaignRoutes/pages/CampaignManageTokens/components/ModalSetLimit/styles.module.sass","webpack://./src/client/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,+BAAA,CACA,cAAA,CACA,yBAAA,CACF,sBACG,0BAAA,CACH,sBACE,yBAAA,CCFC,yBDCH,sBAGI,yBAAA,CAAA","sourcesContent":["@import \"@/client/assets/sass/base/breakpoints\"\n\n.description\n  color: var(--color-default-text)\n  font-size: 14px\n  margin-top: 5px !important\n.marginTop\n   margin-top: 35px !important\n.pxBtn\n  padding: 0 30px !important\n  @include small-mobile\n    padding: 0 12px !important\n","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n   @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin sm   \n  @media (min-width: #{$screen-sm})\n    @content\n\n@mixin md\n   @media (min-width: #{$screen-md})\n    @content\n\n@mixin lg\n  @media (min-width: #{$screen-lg})\n    @content\n\n@mixin xl\n  @media (min-width: #{$screen-xl})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": "xajpiisda02qlmxX7h1A",
	"marginTop": "rs7bw2R27XXIpo_mgzkU",
	"pxBtn": "LcLhWq2gO4OQgR0zJPIU"
};
export default ___CSS_LOADER_EXPORT___;
