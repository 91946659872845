// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Uscb0kH1h_wQmnHSLAow{border:none;padding:4px 10px;border-radius:4px;color:#fff;background:#b02a37}", "",{"version":3,"sources":["webpack://./src/client/pages/User/pages/CampaignTemplates/pages/CreateTemplate/components/CategoryManager/components/RemoveButton/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,gBAAA,CACA,iBAAA,CACA,UAAA,CACA,kBAAA","sourcesContent":[".button\n  border: none\n  padding: 4px 10px\n  border-radius: 4px\n  color: #fff\n  background: #b02a37\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "Uscb0kH1h_wQmnHSLAow"
};
export default ___CSS_LOADER_EXPORT___;
