// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DCkfceFdDwv0Gim6rGEr{width:100%;text-align:center}@media(max-width: 768px){.DCkfceFdDwv0Gim6rGEr{height:85px}}.HNClCTqsCdXbYU1v0iTP{font-size:14px;font-weight:600;color:var(--color-main);padding-top:6px}@media(max-width: 768px){.HNClCTqsCdXbYU1v0iTP{padding-top:10px}}.hk3EiSYxfdnw0aYI5iK4{margin-top:10px}@media(max-width: 768px){.hk3EiSYxfdnw0aYI5iK4{margin-top:12px}}.CdPjZVCd5PvOcXxqBe2U{margin-top:-3px}", "",{"version":3,"sources":["webpack://./src/client/components/organisms/tokens/ManageTokens/components/BulkDistributionChannels/styles.module.sass","webpack://./src/client/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,UAAA,CACA,iBAAA,CCOA,yBDTF,sBAII,WAAA,CAAA,CACJ,sBACE,cAAA,CACA,eAAA,CACA,uBAAA,CACA,eAAA,CAAA,yBAJF,sBAMI,gBAAA,CAAA,CACJ,sBACE,eAAA,CCJA,yBDGF,sBAGI,eAAA,CAAA,CACJ,sBACE,eAAA","sourcesContent":["@import \"@/client/assets/sass/base/breakpoints\"\n\n.distributionChannelsContainer\n  width: 100%\n  text-align: center\n  @include mobile\n    height: 85px\n.subtitle\n  font-size: 14px\n  font-weight: 600\n  color: var(--color-main)\n  padding-top: 6px\n  @include mobile\n    padding-top: 10px\n.marginTop\n  margin-top: 10px\n  @include mobile\n    margin-top: 12px\n.marginMoreBtn\n  margin-top: -3px","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n   @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin sm   \n  @media (min-width: #{$screen-sm})\n    @content\n\n@mixin md\n   @media (min-width: #{$screen-md})\n    @content\n\n@mixin lg\n  @media (min-width: #{$screen-lg})\n    @content\n\n@mixin xl\n  @media (min-width: #{$screen-xl})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"distributionChannelsContainer": "DCkfceFdDwv0Gim6rGEr",
	"subtitle": "HNClCTqsCdXbYU1v0iTP",
	"marginTop": "hk3EiSYxfdnw0aYI5iK4",
	"marginMoreBtn": "CdPjZVCd5PvOcXxqBe2U"
};
export default ___CSS_LOADER_EXPORT___;
