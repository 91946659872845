// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UhKwd8teTGc_mGI7p3Xv{width:60px;height:60px;border-radius:100%;background:rgba(255,255,255,.7);border:2px solid var(--color-secondary);display:flex;position:absolute;left:0;right:0;top:0;bottom:0;margin:auto;justify-content:center;align-items:center;cursor:pointer}.UhKwd8teTGc_mGI7p3Xv:hover{width:65px;height:65px}", "",{"version":3,"sources":["webpack://./src/client/components/wrappers/buttons/ButtonPreview/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,WAAA,CACA,kBAAA,CACA,+BAAA,CACA,uCAAA,CACA,YAAA,CACA,iBAAA,CACA,MAAA,CACA,OAAA,CACA,KAAA,CACA,QAAA,CACA,WAAA,CACA,sBAAA,CACA,kBAAA,CACA,cAAA,CACA,4BACE,UAAA,CACA,WAAA","sourcesContent":[".buttonPreview\n  width: 60px\n  height: 60px\n  border-radius: 100%\n  background: rgba(255, 255, 255, 0.7)\n  border: 2px solid var(--color-secondary)\n  display: flex\n  position: absolute\n  left: 0\n  right: 0\n  top: 0\n  bottom: 0\n  margin: auto\n  justify-content: center\n  align-items: center\n  cursor: pointer\n  &:hover\n    width: 65px\n    height: 65px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonPreview": "UhKwd8teTGc_mGI7p3Xv"
};
export default ___CSS_LOADER_EXPORT___;
