// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EWK3I9U3pUApsVQlszFH{font-size:13px;padding:5px;border-radius:5px;display:inline-block;margin-right:10px;background:var(--color-secondary-darker);line-height:1;color:#fff}.agCwAYg78B2C8hbjz1_0{background:rgba(0,0,0,0);border:none;line-height:1;color:#fff;cursor:pointer}", "",{"version":3,"sources":["webpack://./src/client/pages/CampaignsRoutes/pages/CampaignRoutes/pages/Campaign/components/Recipients/components/Filters/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,WAAA,CACA,iBAAA,CACA,oBAAA,CACA,iBAAA,CACA,wCAAA,CACA,aAAA,CACA,UAAA,CACF,sBACE,wBAAA,CACA,WAAA,CACA,aAAA,CACA,UAAA,CACA,cAAA","sourcesContent":[".filter\n  font-size: 13px\n  padding: 5px\n  border-radius: 5px\n  display: inline-block\n  margin-right: 10px\n  background: var(--color-secondary-darker)\n  line-height: 1\n  color: #fff \n.button\n  background: transparent\n  border: none\n  line-height: 1\n  color: #fff \n  cursor: pointer\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter": "EWK3I9U3pUApsVQlszFH",
	"button": "agCwAYg78B2C8hbjz1_0"
};
export default ___CSS_LOADER_EXPORT___;
