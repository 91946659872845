// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UypUFfftwXZfB1aJeoK8{background:var(--color-secondary);border-bottom-color:rgba(0,0,0,0);border-left-color:rgba(0,0,0,0);border-radius:4px;border-right-color:rgba(0,0,0,0);border-top-color:rgba(0,0,0,0);border:1px solid rgba(0,0,0,0);box-shadow:none;cursor:pointer;position:relative;font-size:14px;color:#fff;align-items:center}._n7YqacgIdp9p_ab1wiO{display:flex;line-height:1;align-items:center;margin-bottom:15px}.ghlMB5SFEpp_u8qBC5Ob{margin-right:10px}", "",{"version":3,"sources":["webpack://./src/client/pages/User/pages/CampaignTemplates/pages/CreateTemplate/components/CategoryManager/components/Category/components/FormHeaderDetails/components/ButtonSelectIcon/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,iCAAA,CACA,iCAAA,CACA,+BAAA,CACA,iBAAA,CACA,gCAAA,CACA,8BAAA,CACA,8BAAA,CACA,eAAA,CACA,cAAA,CACA,iBAAA,CACA,cAAA,CACA,UAAA,CACA,kBAAA,CACF,sBACE,YAAA,CACA,aAAA,CACA,kBAAA,CACA,kBAAA,CACF,sBACE,iBAAA","sourcesContent":[".button\n  background: var(--color-secondary)\n  border-bottom-color: transparent\n  border-left-color: transparent\n  border-radius: 4px\n  border-right-color: transparent\n  border-top-color: transparent\n  border: 1px solid transparent\n  box-shadow: none\n  cursor: pointer\n  position: relative\n  font-size: 14px\n  color: #fff\n  align-items: center\n.buttonContainer\n  display: flex\n  line-height: 1\n  align-items: center\n  margin-bottom: 15px\n.icon\n  margin-right: 10px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "UypUFfftwXZfB1aJeoK8",
	"buttonContainer": "_n7YqacgIdp9p_ab1wiO",
	"icon": "ghlMB5SFEpp_u8qBC5Ob"
};
export default ___CSS_LOADER_EXPORT___;
