// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kcctkBwg2h44PfilB4vc{border-radius:3px;height:3.75rem;min-width:215px;border:none;font-size:20px;cursor:pointer;font-weight:600}.X6gGndi7jSyVj8D_ygc9{background:var(--color-links);color:#fff}.bY3X4EGc02CfIpQd1Nd1{background:rgba(0,0,0,0);color:var(--color-links)}", "",{"version":3,"sources":["webpack://./src/client/components/atoms/buttons/ButtonBig/styles.module.sass","webpack://./src/client/assets/sass/base/_units.sass"],"names":[],"mappings":"AAEA,sBACE,iBAAA,CACA,cCaI,CDZJ,eAAA,CACA,WAAA,CACA,cAAA,CACA,cAAA,CACA,eAAA,CACF,sBACE,6BAAA,CACA,UAAA,CACF,sBACE,wBAAA,CACA,wBAAA","sourcesContent":["@import \"@/client/assets/sass/base/units\"\n\n.btnBig\n  border-radius: 3px\n  height: $u15\n  min-width: 215px\n  border: none\n  font-size: 20px\n  cursor: pointer\n  font-weight: 600\n.action\n  background: var(--color-links)\n  color: #fff\n.transparent\n  background: transparent\n  color: var(--color-links)\n","$u1: 0.25rem      // 4px\n$u1-5: 0.375rem   // 6px\n$u2: 0.5rem       // 8px\n$u2-5: 0.625rem   // 10px\n$u3: 0.75rem      // 12px\n$u3-5: 0.875rem   // 14px\n$u4: 1rem         // 16px\n$u5: 1.25rem      // 20px\n$u6: 1.5rem       // 24px\n$u7: 1.75rem      // 28px\n$u8: 2rem         // 32px\n$u9: 2.25rem      // 36px\n$u10: 2.5rem      // 40px\n$u11: 2.75rem     // 44px\n$u12: 3rem        // 48px\n$u13: 3.25rem     // 52px\n$u14: 3.5rem      // 56px\n$u15: 3.75rem     // 60px\n$u16: 4rem        // 64px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnBig": "kcctkBwg2h44PfilB4vc",
	"action": "X6gGndi7jSyVj8D_ygc9",
	"transparent": "bY3X4EGc02CfIpQd1Nd1"
};
export default ___CSS_LOADER_EXPORT___;
