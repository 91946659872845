// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sZwe8_GHjVXpH1s6bCws{border-radius:8px;border-width:5px;border-style:solid;border-color:rgba(255,255,255,.2)}.sZwe8_GHjVXpH1s6bCws .ytCEcue5LtmHXq2DUUgm{height:3.75rem;padding:.875rem 1.25rem;border:none;border-radius:3px;background:#fff;width:100%;font-size:1.25rem}.sZwe8_GHjVXpH1s6bCws .ytCEcue5LtmHXq2DUUgm::placeholder{color:#d8dadb}", "",{"version":3,"sources":["webpack://./src/client/components/atoms/form/InputBig/styles.module.sass","webpack://./src/client/assets/sass/base/_units.sass"],"names":[],"mappings":"AAGA,sBACE,iBAAA,CACA,gBAAA,CACA,kBAAA,CACA,iCAAA,CACA,4CACE,cCQE,CDPF,uBAAA,CACA,WAAA,CACA,iBAAA,CACA,eAAA,CACA,UAAA,CACA,iBCRC,CDSD,yDACE,aAAA","sourcesContent":["@import \"@/client/assets/sass/base/units\"\n@import \"@/client/assets/sass/base/fonts/sizes\"\n\n.dpsInput\n  border-radius: 8px\n  border-width: 5px\n  border-style: solid\n  border-color: rgba(255, 255, 255, 0.2)\n  .input\n    height: $u15\n    padding: $u3-5 $u5\n    border: none\n    border-radius: 3px\n    background: #fff\n    width: 100%\n    font-size: $text-size-lg\n    &::placeholder\n      color: #D8DADB","$u1: 0.25rem      // 4px\n$u1-5: 0.375rem   // 6px\n$u2: 0.5rem       // 8px\n$u2-5: 0.625rem   // 10px\n$u3: 0.75rem      // 12px\n$u3-5: 0.875rem   // 14px\n$u4: 1rem         // 16px\n$u5: 1.25rem      // 20px\n$u6: 1.5rem       // 24px\n$u7: 1.75rem      // 28px\n$u8: 2rem         // 32px\n$u9: 2.25rem      // 36px\n$u10: 2.5rem      // 40px\n$u11: 2.75rem     // 44px\n$u12: 3rem        // 48px\n$u13: 3.25rem     // 52px\n$u14: 3.5rem      // 56px\n$u15: 3.75rem     // 60px\n$u16: 4rem        // 64px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dpsInput": "sZwe8_GHjVXpH1s6bCws",
	"input": "ytCEcue5LtmHXq2DUUgm"
};
export default ___CSS_LOADER_EXPORT___;
