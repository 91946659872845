// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fZFcbdAkA17yHXFCImIQ{color:#64beaa;font-size:14px;margin-right:10px;font-weight:700}.fZFcbdAkA17yHXFCImIQ:last-child{margin-right:0}", "",{"version":3,"sources":["webpack://./src/client/pages/CampaignsRoutes/pages/CampaignRoutes/pages/Campaign/components/Recipients/components/ActionsButtons/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,aAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,iCACE,cAAA","sourcesContent":[".iconRecipient\n  color: #64beaa \n  font-size: 14px \n  margin-right: 10px\n  font-weight: 700\n  &:last-child\n    margin-right: 0\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconRecipient": "fZFcbdAkA17yHXFCImIQ"
};
export default ___CSS_LOADER_EXPORT___;
