// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SVpYaZ9w67vBU33ykyK3{width:100%;margin-top:20px}.KC1DnQS6_iFDkYvUL4nw{width:4%}.lVf444HRjS7BkMa98iOp{width:76%}.FEKzqOfRvAduakvRUeSK{width:20%}.OkFrVA9zzWDlTCo7fwpk{font-weight:600;color:var(--color-main)}", "",{"version":3,"sources":["webpack://./src/client/components/organisms/tokens/Certifications/styles.module.sass"],"names":[],"mappings":"AAEA,sBACE,UAAA,CACA,eAAA,CACF,sBACE,QAAA,CACF,sBACE,SAAA,CACF,sBACE,SAAA,CACF,sBACE,eAAA,CACA,uBAAA","sourcesContent":["@import \"@/client/assets/sass/base/breakpoints\"\n\n.certificationsContainer  \n  width: 100%\n  margin-top: 20px\n.iconColumn  \n  width: 4%  \n.userColumn  \n  width: 76%    \n.tokensColumn\n  width: 20%  \n.certificationTxt\n  font-weight: 600\n  color: var(--color-main) "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"certificationsContainer": "SVpYaZ9w67vBU33ykyK3",
	"iconColumn": "KC1DnQS6_iFDkYvUL4nw",
	"userColumn": "lVf444HRjS7BkMa98iOp",
	"tokensColumn": "FEKzqOfRvAduakvRUeSK",
	"certificationTxt": "OkFrVA9zzWDlTCo7fwpk"
};
export default ___CSS_LOADER_EXPORT___;
