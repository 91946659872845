// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kQMJeKco0AT934IhcPQZ{padding:0 48px;margin-left:16px}@media(max-width: 768px){.kQMJeKco0AT934IhcPQZ{padding:0 30px;margin-left:0}}.D5p9Nan8DlYZQrSrwAm6{padding:0 16px;float:right !important;margin-right:16px}@media(max-width: 768px){.D5p9Nan8DlYZQrSrwAm6{margin-right:0}}@media(max-width: 440px){.D5p9Nan8DlYZQrSrwAm6{float:left !important;margin-top:12px}}", "",{"version":3,"sources":["webpack://./src/client/components/organisms/tokens/ModalAddTokensCertifications/styles.module.sass","webpack://./src/client/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,cAAA,CACA,gBAAA,CCOA,yBDTF,sBAII,cAAA,CACA,aAAA,CAAA,CACJ,sBACE,cAAA,CACA,sBAAA,CACA,iBAAA,CAAA,yBAHF,sBAKI,cAAA,CAAA,CCND,yBDCH,sBAOI,qBAAA,CACA,eAAA,CAAA","sourcesContent":["@import \"@/client/assets/sass/base/breakpoints\"\n\n.cancelBtn\n  padding: 0 48px\n  margin-left: 16px\n  @include mobile\n    padding: 0 30px\n    margin-left: 0\n.certificationsBtn\n  padding: 0 16px\n  float: right !important\n  margin-right: 16px\n  @include mobile\n    margin-right: 0\n  @include small-mobile\n    float: left !important\n    margin-top: 12px","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n   @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin sm   \n  @media (min-width: #{$screen-sm})\n    @content\n\n@mixin md\n   @media (min-width: #{$screen-md})\n    @content\n\n@mixin lg\n  @media (min-width: #{$screen-lg})\n    @content\n\n@mixin xl\n  @media (min-width: #{$screen-xl})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cancelBtn": "kQMJeKco0AT934IhcPQZ",
	"certificationsBtn": "D5p9Nan8DlYZQrSrwAm6"
};
export default ___CSS_LOADER_EXPORT___;
