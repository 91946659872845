// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pOWVRAFsu56BLbFRNeu1{font-weight:600}.koqIsyukvQKClE6oHFgQ{cursor:not-allowed}", "",{"version":3,"sources":["webpack://./src/client/components/organisms/tokens/ShareLink/components/CopyButton/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACF,sBACE,kBAAA","sourcesContent":[".toolTipLabel  \n  font-weight: 600  \n.btnDisabled\n  cursor: not-allowed  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolTipLabel": "pOWVRAFsu56BLbFRNeu1",
	"btnDisabled": "koqIsyukvQKClE6oHFgQ"
};
export default ___CSS_LOADER_EXPORT___;
