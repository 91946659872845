// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Uqz5Ptuf_ZBTz7JkzuU5{width:50% !important;margin:0 0 35px 35px}@media(max-width: 768px){.Uqz5Ptuf_ZBTz7JkzuU5{width:84% !important}}@media(max-width: 440px){.Uqz5Ptuf_ZBTz7JkzuU5{width:87% !important;margin:0 20px 35px 20px}}", "",{"version":3,"sources":["webpack://./src/client/pages/Sats/pages/PublisherManageMinting/styles.module.sass","webpack://./src/client/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,oBAAA,CACA,oBAAA,CCOA,yBDTF,sBAII,oBAAA,CAAA,CCCD,yBDLH,sBAMI,oBAAA,CACA,uBAAA,CAAA","sourcesContent":["@import \"@/client/assets/sass/base/breakpoints\"\n\n.pageContainer\n  width: 50% !important\n  margin: 0 0 35px 35px\n  @include mobile\n    width: 84% !important\n  @include small-mobile\n    width: 87% !important\n    margin: 0 20px 35px 20px","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n   @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin sm   \n  @media (min-width: #{$screen-sm})\n    @content\n\n@mixin md\n   @media (min-width: #{$screen-md})\n    @content\n\n@mixin lg\n  @media (min-width: #{$screen-lg})\n    @content\n\n@mixin xl\n  @media (min-width: #{$screen-xl})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContainer": "Uqz5Ptuf_ZBTz7JkzuU5"
};
export default ___CSS_LOADER_EXPORT___;
