// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vl8UAZLOQne5YeH4bEXg{display:flex;justify-content:space-between;margin:0 33px 35px 33px}@media(max-width: 768px){.vl8UAZLOQne5YeH4bEXg{display:block}}@media(max-width: 440px){.vl8UAZLOQne5YeH4bEXg{margin:0 16px 35px 16px}}.vfBV2fLCbkaVKOsfBxs6{width:48.5%}@media(max-width: 768px){.vfBV2fLCbkaVKOsfBxs6{width:100%}}.EbpPwMPfYYEZdwnoBy1C{width:100%;margin-top:16px}", "",{"version":3,"sources":["webpack://./src/client/pages/Listings/pages/ViewAsset/styles.module.sass","webpack://./src/client/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,6BAAA,CACA,uBAAA,CCMA,yBDTF,sBAKI,aAAA,CAAA,CAAA,yBALJ,sBAOI,uBAAA,CAAA,CACJ,sBACE,WAAA,CAAA,yBADF,sBAGI,UAAA,CAAA,CACJ,sBACE,UAAA,CACA,eAAA","sourcesContent":["@import \"@/client/assets/sass/base/breakpoints\"\n\n.viewContainer\n  display: flex\n  justify-content: space-between\n  margin: 0 33px 35px 33px  \n  @include mobile\n    display: block\n  @include small-mobile\n    margin: 0 16px 35px 16px\n.detailsContainer\n  width: 48.5%      \n  @include mobile\n    width: 100%\n.currentLicensesContainer\n  width: 100%\n  margin-top: 16px\n","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n   @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin sm   \n  @media (min-width: #{$screen-sm})\n    @content\n\n@mixin md\n   @media (min-width: #{$screen-md})\n    @content\n\n@mixin lg\n  @media (min-width: #{$screen-lg})\n    @content\n\n@mixin xl\n  @media (min-width: #{$screen-xl})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"viewContainer": "vl8UAZLOQne5YeH4bEXg",
	"detailsContainer": "vfBV2fLCbkaVKOsfBxs6",
	"currentLicensesContainer": "EbpPwMPfYYEZdwnoBy1C"
};
export default ___CSS_LOADER_EXPORT___;
