// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NiSa4cuKp85wiH3gtMFd{width:320px}@media(max-width: 768px){.NiSa4cuKp85wiH3gtMFd{width:100%;margin-left:7px}}.NiSa4cuKp85wiH3gtMFd ul{table-layout:auto !important}.qcsqfABB4O2GV0jY9T4f{width:280px;font-size:12px;margin-left:20px;margin-bottom:30px}", "",{"version":3,"sources":["webpack://./src/client/components/organisms/assets/PNFTCard/styles.module.sass","webpack://./src/client/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,WAAA,CCQA,yBDTF,sBAGI,UAAA,CACA,eAAA,CAAA,CACJ,yBACE,4BAAA,CACF,sBACE,WAAA,CACA,cAAA,CACA,gBAAA,CACA,kBAAA","sourcesContent":["@import \"@/client/assets/sass/base/breakpoints\"\n\n.pnftCard\n  width: 320px  \n  @include mobile\n    width: 100%\n    margin-left: 7px\n.pnftCard ul  \n  table-layout: auto !important\n.pnftContent\n  width: 280px\n  font-size: 12px    \n  margin-left: 20px\n  margin-bottom: 30px   ","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n   @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin sm   \n  @media (min-width: #{$screen-sm})\n    @content\n\n@mixin md\n   @media (min-width: #{$screen-md})\n    @content\n\n@mixin lg\n  @media (min-width: #{$screen-lg})\n    @content\n\n@mixin xl\n  @media (min-width: #{$screen-xl})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pnftCard": "NiSa4cuKp85wiH3gtMFd",
	"pnftContent": "qcsqfABB4O2GV0jY9T4f"
};
export default ___CSS_LOADER_EXPORT___;
