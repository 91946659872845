// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vfixsZxfVzwXe99_Fi7B{color:var(--color-main) !important}.augS0vZn3tEMyT3q995k{margin-top:7px;margin-right:5px}@media(max-width: 768px){.augS0vZn3tEMyT3q995k{margin-right:10px}}.fuuHmWkdt44HHQPS9KwK{margin-top:7px;margin-left:5px}@media(max-width: 768px){.fuuHmWkdt44HHQPS9KwK{margin-left:10px}}", "",{"version":3,"sources":["webpack://./src/client/components/organisms/tokens/TokenSlider/styles.module.sass","webpack://./src/client/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,kCAAA,CACF,sBACE,cAAA,CACA,gBAAA,CCKA,yBDPF,sBAII,iBAAA,CAAA,CACJ,sBACE,cAAA,CACA,eAAA,CAAA,yBAFF,sBAII,gBAAA,CAAA","sourcesContent":["@import \"@/client/assets/sass/base/breakpoints\"\n\n.labelColor\n  color: var(--color-main) !important   \n.from\n  margin-top: 7px\n  margin-right: 5px\n  @include mobile\n    margin-right: 10px\n.to  \n  margin-top: 7px    \n  margin-left: 5px\n  @include mobile\n    margin-left: 10px","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n   @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin sm   \n  @media (min-width: #{$screen-sm})\n    @content\n\n@mixin md\n   @media (min-width: #{$screen-md})\n    @content\n\n@mixin lg\n  @media (min-width: #{$screen-lg})\n    @content\n\n@mixin xl\n  @media (min-width: #{$screen-xl})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labelColor": "vfixsZxfVzwXe99_Fi7B",
	"from": "augS0vZn3tEMyT3q995k",
	"to": "fuuHmWkdt44HHQPS9KwK"
};
export default ___CSS_LOADER_EXPORT___;
