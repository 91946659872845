// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HZQTJv_CgNtfAKXPr83b{height:100%;margin-top:-10px}@media(max-width: 768px){.HZQTJv_CgNtfAKXPr83b{height:106%;overflow:auto}}.HZQTJv_CgNtfAKXPr83b img{width:550px;height:420px;object-fit:cover}@media(max-width: 768px){.HZQTJv_CgNtfAKXPr83b img{width:100%;height:160px;margin:0 auto !important}}", "",{"version":3,"sources":["webpack://./src/client/pages/Assets/pages/Assets/components/modal/ModalPreview/components/BodyModal/styles.module.sass","webpack://./src/client/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,WAAA,CACA,gBAAA,CCOA,yBDTF,sBAII,WAAA,CACA,aAAA,CAAA,CACJ,0BACE,WAAA,CACA,YAAA,CACA,gBAAA,CAAA,yBAHF,0BAKI,UAAA,CACA,YAAA,CACA,wBAAA,CAAA","sourcesContent":["@import \"@/client/assets/sass/base/breakpoints\"\n\n.imgContainer  \n  height: 100%\n  margin-top: -10px  \n  @include mobile    \n    height: 106%\n    overflow: auto\n.imgContainer img\n  width: 550px\n  height: 420px    \n  object-fit: cover\n  @include mobile\n    width: 100%\n    height: 160px    \n    margin: 0 auto !important    ","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n   @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin sm   \n  @media (min-width: #{$screen-sm})\n    @content\n\n@mixin md\n   @media (min-width: #{$screen-md})\n    @content\n\n@mixin lg\n  @media (min-width: #{$screen-lg})\n    @content\n\n@mixin xl\n  @media (min-width: #{$screen-xl})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imgContainer": "HZQTJv_CgNtfAKXPr83b"
};
export default ___CSS_LOADER_EXPORT___;
