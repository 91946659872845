// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OIKVEXAtdv94S3kd250f{display:flex;flex-wrap:wrap}@media(max-width: 1048px){.OIKVEXAtdv94S3kd250f{justify-content:center}}.OExs6m0QGtMUJWbFfgJT{margin-right:24px}@media(max-width: 768px){.OExs6m0QGtMUJWbFfgJT{margin-right:0}}", "",{"version":3,"sources":["webpack://./src/client/pages/Listings/pages/Listings/components/ListingAssets/styles.module.sass","webpack://./src/client/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,cAAA,CCWA,0BDbF,sBAII,sBAAA,CAAA,CACJ,sBACE,iBAAA,CCGA,yBDJF,sBAGI,cAAA,CAAA","sourcesContent":["@import \"@/client/assets/sass/base/breakpoints\"\n\n.assetsCardsContainer\n  display:  flex\n  flex-wrap: wrap\n  @include small-pc\n    justify-content: center\n.marginRight\n  margin-right: 24px\n  @include mobile\n    margin-right: 0    \n","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n   @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin sm   \n  @media (min-width: #{$screen-sm})\n    @content\n\n@mixin md\n   @media (min-width: #{$screen-md})\n    @content\n\n@mixin lg\n  @media (min-width: #{$screen-lg})\n    @content\n\n@mixin xl\n  @media (min-width: #{$screen-xl})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"assetsCardsContainer": "OIKVEXAtdv94S3kd250f",
	"marginRight": "OExs6m0QGtMUJWbFfgJT"
};
export default ___CSS_LOADER_EXPORT___;
