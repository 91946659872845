// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kBNGwX8u1Dsh6kMi8aau{margin:0 35px;background-color:#f3f4f5 !important;padding:48px}@media(max-width: 440px){.kBNGwX8u1Dsh6kMi8aau{margin:0 20px;padding:20px}}.IGllYRXNH8q6mKXNXMMQ{padding-left:48px;padding-right:48px}@media(max-width: 440px){.IGllYRXNH8q6mKXNXMMQ{padding-left:30px;padding-right:30px}}.SUDuQnriryoFYuY3IENO{width:50%;margin-bottom:20px}", "",{"version":3,"sources":["webpack://./src/client/pages/CampaignsRoutes/pages/CreateCampaign/styles.module.sass","webpack://./src/client/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,aAAA,CACA,mCAAA,CACA,YAAA,CCEC,yBDLH,sBAKI,aAAA,CACA,YAAA,CAAA,CACJ,sBACE,iBAAA,CACA,kBAAA,CCJC,yBDEH,sBAII,iBAAA,CACA,kBAAA,CAAA,CACJ,sBACE,SAAA,CACA,kBAAA","sourcesContent":["@import \"@/client/assets/sass/base/breakpoints\"\n\n.createContainer  \n  margin: 0 35px\n  background-color: #f3f4f5 !important\n  padding: 48px\n  @include small-mobile\n    margin: 0 20px\n    padding: 20px\n.paddingBtn\n  padding-left: 48px\n  padding-right: 48px\n  @include small-mobile\n    padding-left: 30px\n    padding-right: 30px\n.inputContainer\n  width: 50%  \n  margin-bottom: 20px","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n   @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin sm   \n  @media (min-width: #{$screen-sm})\n    @content\n\n@mixin md\n   @media (min-width: #{$screen-md})\n    @content\n\n@mixin lg\n  @media (min-width: #{$screen-lg})\n    @content\n\n@mixin xl\n  @media (min-width: #{$screen-xl})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"createContainer": "kBNGwX8u1Dsh6kMi8aau",
	"paddingBtn": "IGllYRXNH8q6mKXNXMMQ",
	"inputContainer": "SUDuQnriryoFYuY3IENO"
};
export default ___CSS_LOADER_EXPORT___;
