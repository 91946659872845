// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".O_WWmRlcXWC35RhUAPTf{margin:0 35px}", "",{"version":3,"sources":["webpack://./src/client/pages/CampaignsRoutes/pages/CampaignRoutes/pages/SettingsRoutes/pages/UploadPreviewImage/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,aAAA","sourcesContent":[".uploadContainer\n  margin: 0 35px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"uploadContainer": "O_WWmRlcXWC35RhUAPTf"
};
export default ___CSS_LOADER_EXPORT___;
