// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".t3ytPFAV_4fLuxN9zNeG{display:table;border-collapse:collapse;margin:0;padding:0;table-layout:fixed;width:100%}.SEY9GjPpAC2x2UzzBQad{list-style:none;display:table-row}.f8Lkjvz3NLSa7CCeb02g{list-style:none;display:table-cell}", "",{"version":3,"sources":["webpack://./src/client/components/molecules/general/lists/ListAsTable/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,aAAA,CACA,wBAAA,CACA,QAAA,CACA,SAAA,CACA,kBAAA,CACA,UAAA,CACF,sBACE,eAAA,CACA,iBAAA,CACF,sBACE,eAAA,CACA,kBAAA","sourcesContent":[".listTable\n  display: table\n  border-collapse: collapse\n  margin: 0\n  padding: 0\n  table-layout: fixed\n  width: 100%\n.row\n  list-style: none\n  display: table-row\n.cell\n  list-style: none\n  display: table-cell"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listTable": "t3ytPFAV_4fLuxN9zNeG",
	"row": "SEY9GjPpAC2x2UzzBQad",
	"cell": "f8Lkjvz3NLSa7CCeb02g"
};
export default ___CSS_LOADER_EXPORT___;
