// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SyugPnloPSFKnBqrlxZJ{background:none;border-left:1px solid var(--color-border-lighter) !important}.SyugPnloPSFKnBqrlxZJ:hover{background:var(--color-main)}.SyugPnloPSFKnBqrlxZJ:hover img{filter:brightness(0) invert(1)}@media(max-width: 440px){.SyugPnloPSFKnBqrlxZJ{border-left:none !important}}.Wv4IFuWTdDv2jvGaTEUP{border-radius:0 !important}", "",{"version":3,"sources":["webpack://./src/client/components/organisms/campaigns/CampaignCard/components/Footer/components/Actions/styles.module.sass","webpack://./src/client/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,eAAA,CACA,4DAAA,CACA,4BACE,4BAAA,CACA,gCACE,8BAAA,CCDH,yBDLH,sBAQI,2BAAA,CAAA,CACJ,sBACE,0BAAA","sourcesContent":["@import \"@/client/assets/sass/base/breakpoints\"\n\n.iconButton\n  background: none\n  border-left: 1px solid var(--color-border-lighter) !important\n  &:hover\n    background: var(--color-main) \n    img\n      filter: brightness(0) invert(1)\n  @include small-mobile\n    border-left: none !important    \n.detailsBtn  \n  border-radius: 0 !important\n","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n   @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin sm   \n  @media (min-width: #{$screen-sm})\n    @content\n\n@mixin md\n   @media (min-width: #{$screen-md})\n    @content\n\n@mixin lg\n  @media (min-width: #{$screen-lg})\n    @content\n\n@mixin xl\n  @media (min-width: #{$screen-xl})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconButton": "SyugPnloPSFKnBqrlxZJ",
	"detailsBtn": "Wv4IFuWTdDv2jvGaTEUP"
};
export default ___CSS_LOADER_EXPORT___;
