// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jCnERxB0zR815MxIp66b{margin:0 35px 20px 35px}.RIu4SzOHY7tG6XX2TePE{border:8px solid #d1d3da}.N_QTF4mTD9z667wAMhvf{display:flex;flex-wrap:wrap}@media(max-width: 768px){.N_QTF4mTD9z667wAMhvf{justify-content:center}}", "",{"version":3,"sources":["webpack://./src/client/pages/CampaignsRoutes/pages/CampaignRoutes/pages/SettingsRoutes/pages/FeatureImageCampaign/styles.module.sass","webpack://./src/client/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,uBAAA,CACF,sBACE,wBAAA,CACF,sBACE,YAAA,CACA,cAAA,CCGA,yBDLF,sBAII,sBAAA,CAAA","sourcesContent":["@import \"@/client/assets/sass/base/breakpoints\"\n\n.formContainer\n  margin: 0 35px 20px 35px    \n.cardBorder\n  border: 8px solid #d1d3da\n.assetsCardsContainer\n  display:  flex\n  flex-wrap: wrap\n  @include mobile\n    justify-content: center","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n   @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin sm   \n  @media (min-width: #{$screen-sm})\n    @content\n\n@mixin md\n   @media (min-width: #{$screen-md})\n    @content\n\n@mixin lg\n  @media (min-width: #{$screen-lg})\n    @content\n\n@mixin xl\n  @media (min-width: #{$screen-xl})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formContainer": "jCnERxB0zR815MxIp66b",
	"cardBorder": "RIu4SzOHY7tG6XX2TePE",
	"assetsCardsContainer": "N_QTF4mTD9z667wAMhvf"
};
export default ___CSS_LOADER_EXPORT___;
